import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import * as RouteConstants from 'constants/routes';

import { appStorage } from 'utils/storage';
import { removeStorage } from "../../pages/Logout";
import { ErrorLogger } from 'utils/errorLogger';

const getRequestId = (headers?: AxiosResponse['headers']) => {
  return headers?.['request-id'] || '';
}

enum REQUEST_STATUS {
  LOADING = 'LOADING',
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
}

export const passportService = axios.create({
  baseURL: '/connections-manager',
});

type CxmApiError = {
  code: number,
  message: string,
  user_message: string
}

type CxmErrorConfig = {
  overrideErrorInterceptor?: boolean
}


const AxiosInterceptor = ({ children }) => {
  const navigate = useNavigate();

  const logout = () => {
    removeStorage();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const requestInterceptor = (config: AxiosRequestConfig<any>) => {
    const jwtToken = appStorage.getItem('JWT_TOKEN');

    if (jwtToken) {
      config.headers = {
        'content-type': 'application/json',
        'Cache-Control': 'no-cache',
        accept: 'application/json',
        authorization: jwtToken,
      };
    }

    return config;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const responseInterceptor = (config: AxiosResponse<any, any>) => {
    const authorization = config?.headers?.authorization;
    const requestId = getRequestId(config?.headers);
    // const publicKey = config?.headers?.['public-key'];

    if (authorization) {
      appStorage.setItem('JWT_TOKEN', authorization);
    }

    ErrorLogger.breadcrumb({
      message:  `API call - ${config?.config?.url} - ${config?.config?.method}`,
      category: 'api_call',
      level: 'info',
      data: {
        requestId,
      }
    });

    // if(!authorization && !publicKey) {
    //   appStorage.removeItem('JWT_TOKEN');
    // }

    return config;
  };

  const apiErrorLogger = (error: AxiosError) => {
    if (!error) return;
    const statusCode = Number(error?.response?.status);
    const requestId = getRequestId(error?.response?.headers);
    const url = error?.config?.url;
    const payload = {
      url: url,
      status: statusCode,
      message: error?.message,
      requestId,
    };

    if ([401, 403].includes(statusCode)) {
      return;
    } else if (statusCode >= 300 && statusCode < 400) {
      ErrorLogger.error('API error 3xx', payload);
    } else if (statusCode >= 400 && statusCode < 500) {
      ErrorLogger.error('API error 4xx', payload);
    } else if (statusCode >= 500) {
      ErrorLogger.error('API error 5xx', payload);
    }
  };


  const responseError = (error: AxiosError<CxmApiError, CxmErrorConfig>) => {
    const flow = appStorage.getItem('flow');
    const { config, response } = error;
    const status = response?.status;
    const requestId = getRequestId(error?.response?.headers)

    apiErrorLogger(error);

    /*    if(flow === 'rux')
        {
          sendApiErrorNotification();
          return;
        }*/


    /*if (status && status >= 500) {
      if (url === '/customers/details') {
        throw error;
      }
      else {
        logout();
        navigate(RouteConstants.SYSTEM_DOWN);
      }
    }*/
    if (flow !== 'rux') {
      if (status === 401) {
        logout();
        ErrorLogger.breadcrumb({
          message: 'Unauthorized',
          category: 'api_call',
          level: 'info',
          data: {
            requestId,
          }
        });
        return navigate(RouteConstants.SIGN_IN);
      }

      if (status === 429 && response?.data.message === 'Limit reached') {
        logout();
        return navigate(RouteConstants.ACCOUNT_LOCKED);
      }

      if (status === 403 && !config?.overrideErrorInterceptor) {
        logout();
        ErrorLogger.breadcrumb({
          message: 'Account Locked(403)',
          category: 'api_call',
          level: 'info',
          data: {
            requestId,
          }
        });
        if (response?.data?.code === 1002) {
          return navigate(RouteConstants.ACCOUNT_LOCKED);
        }
        return navigate(RouteConstants.SIGN_IN);
      }
    }
    throw error;
  };

  const reqInterceptor = useMemo(() => passportService.interceptors.request.use(requestInterceptor), []);
  const resInterceptor = useMemo(() => passportService.interceptors.response.use(responseInterceptor, responseError), []);

  useEffect(() => {
    return () => {
      passportService.interceptors.request.eject(reqInterceptor);
      passportService.interceptors.response.eject(resInterceptor);
    };
  }, []);

  return children;
};

const getTimeoutByNetworkConnection = () => {
  const SECOND = 1000;
  const connection = navigator.connection || navigator.webkitConnection || navigator.mozConnection;

  // Note: If there is no browser support, we will default the timeout to 10 Seconds
  if (!connection) {
    return 10 * SECOND;
  }

  if (connection.effectiveType === '4g') {
    return 10 * SECOND;
  }

  if (connection.effectiveType === '3g') {
    return 15 * SECOND;
  }

  return 20 * SECOND;
}

async function service(requestParams: AxiosRequestConfig & CxmErrorConfig) {
  return passportService.request({
    timeout: getTimeoutByNetworkConnection(),
    ...requestParams,
  });
}

export { service as default, AxiosInterceptor, REQUEST_STATUS };
