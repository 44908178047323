import * as SentryReact from '@sentry/react';

const getTracesSampleRate = (env: string) => {
  if (env === 'production') {
    return 1;
  } else if (env === 'staging') {
    return 0.25;
  }
  return 0.1;
};

const init = (env: string) => {
  const sentryClient = SentryReact.getCurrentHub().getClient();
  if (!sentryClient) {
    SentryReact.addTracingExtensions();
    SentryReact.init({
      dsn: 'https://c3c194627c6966c3332875847e7dd3bd@o375285.ingest.sentry.io/4505982826643456',
      environment: env,
      tracesSampleRate: getTracesSampleRate(env),
      tracePropagationTargets: [window.location.origin],
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 0,
      beforeSend(event) {
        const connectPlusSessionId = sessionStorage.getItem('connectPlusSessionId');
        if (connectPlusSessionId) {
          event.contexts = {
            ...event.contexts,
            metadata: JSON.parse(JSON.stringify({
              connectPlusSessionId
            })),
          };
        }
        return event;
      },
    });
  }
};

const breadcrumb = ({
  message,
  category = 'btn_click',
  level = 'info',
  data,
}: {
  message: string;
  category: 'btn_click' | 'api_call';
  level?: SentryReact.Breadcrumb['level'];
  data?: SentryReact.Breadcrumb['data'];
}) => {
  SentryReact.addBreadcrumb({
    message,
    category,
    level,
    data,
  });
};

const error = (message: any, data?: any) => {
  if (data || typeof message === 'string') {
    SentryReact.withScope((scope) => {
      scope.setContext('Details', data);
      scope.setLevel('fatal');
      SentryReact.captureMessage(message);
      return scope;
    });
  } else {
    SentryReact.captureException(message);
  }
};

let contextInfo: any = {};

const setLogContext = (key: string, value: any, contextName = 'SessionInfo') => {
  if (!contextInfo[contextName]) {
    contextInfo[contextName] = {};
  }
  contextInfo[contextName][key] = value;
  SentryReact.setContext(contextName, contextInfo[contextName]);
};

const metric = ({
  name = 'GuestMode',
  flow
}) => {
  SentryReact.metrics.increment(name, 1, {
    tags: { browser: navigator.userAgent, flow: flow },
  });
};

const reset = () => {
  contextInfo = {};
  SentryReact.setUser(null);
};

export const ErrorLogger = {
  init,
  breadcrumb,
  error,
  setLogContext,
  reset,
  metric,
};
